import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as ReactIconmd from 'react-icons/md';
import * as ReactIcon from 'react-icons/gr';

import { GoogleLogin } from '@react-oauth/google';


import './Login.css';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };


  
  const handleSubmit = (e) => {
    e.preventDefault();

    // Perform login authentication logic here
    // Replace the following condition with your authentication logic
    if(username=="123" && password=="pass"){
      onLogin(true);
    }
  };

  return (
    <div className='backdrop' style={{background:"url(/bg.jpg)","background-size":"cover"}}>
         
    <div className="login-container">
    



<div className='bg'>
   
    <div style={{background:"#FFF",padding:"20px","position":"relative"}}>
    <h1>BBS BANK</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <br/>
          <div htmlFor="username">Username:</div>
          <input type="text" id="username" value={username} onChange={handleUsernameChange} />
        </div>
        <div className="form-group">
          <div htmlFor="password">Password:</div>
          <input type="password" id="password" value={password} onChange={handlePasswordChange} />
        </div>
        <div className="form-group" style={{"text-align":"right"}}>
        <button><ReactIconmd.MdLogin />  Login</button>
       
        </div>
        <hr></hr>
        <GoogleLogin
  onSuccess={credentialResponse => {
    console.log(credentialResponse);
    onLogin(true);
    localStorage.session=credentialResponse.credential;
  }}
  onError={() => {
    console.log('Login Failed');
  }}
/>
        <div id="gs2"></div>
  <a href="#" onclick="signOut();" id="signout"></a>

      </form> 
      </div>
      </div>
      <div className='bg'>
<div style={{background:"#FFF",height:"100%","text-align":"center" }}>
  <br/>
  <img src="/logo192.png" style={{width:"50%"}}/>
  <h1>Corporate<br/>Banking Login</h1>
</div>

</div>
      </div>
    </div>
  );
};

export default Login;
