import React, { useState, useRef } from 'react';
import { IconContext } from 'react-icons';
import networksData from '../../init/networks.json';
import * as ChainIcons from "@thirdweb-dev/chain-icons";
import './SettingsPage.css';

const SettingsPage = () => {
  const [companyName, setCompanyName] = useState('Acme Corp');
  const [companyEmail, setCompanyEmail] = useState('info@acmecorp.com');
  const [companyAddress, setCompanyAddress] = useState('123 Main St, City, Country');

  const inputRefs = useRef([]);

  const handleInputChange = (index, valueSetter) => event => {
    valueSetter(event.target.value);
  };
  const IComponent = ({ value }) => {
    const IconComponent = ChainIcons[value] || (() => <img src={`/images/${value}.svg`} height="25" />);
  
    return (
      <div>
        <IconContext.Provider >{IconComponent && <IconComponent height={25} />}</IconContext.Provider>
      </div>
    );
  };
  const handleCopyAddress = index => () => {
    const input = inputRefs.current[index];
    input.select();
    const addressToCopy = input.value;
    navigator.clipboard.writeText(addressToCopy)
    .then(() => {
      console.log('Address copied to clipboard:', addressToCopy);
    })
    .catch(error => {
      console.error('Failed to copy address to clipboard:', error);
    });
  };

  return (
    <div className="settings-page">
      <h2 className="settings-page__title">Account Settings</h2>
      <div className="settings-page__section">
        <h3 className="settings-page__section-title">Company Information</h3>
        <div className="settings-page__input-group">
          <label htmlFor="companyName">Company Name:</label>
          <input
            type="text"
            id="companyName"
            value={companyName}
            onChange={handleInputChange(0, setCompanyName)}
          />
        </div>
        <div className="settings-page__input-group">
          <label htmlFor="companyEmail">Company Email:</label>
          <input
            type="email"
            id="companyEmail"
            value={companyEmail}
            onChange={handleInputChange(1, setCompanyEmail)}
          />
        </div>
        <div className="settings-page__input-group">
          <label htmlFor="companyAddress">Company Address:</label>
          <textarea
            id="companyAddress"
            value={companyAddress}
            onChange={handleInputChange(2, setCompanyAddress)}
          />
        </div>
      </div>
      <div className="settings-page__section">
        <h3 className="settings-page__section-title">Contract Addresses</h3>
        {networksData.map((network, index) => (
          <div className="settings-page__input-group" key={network.id}>
            <label htmlFor={`contractAddress_${network.name}`}>  <IComponent value={network.icon} /> {network.label}</label>
            <input
              type="text"
              id={`contractAddress_${network.name}`}
              ref={ref => (inputRefs.current[index] = ref)}
              value={network.contractAddress}
              readOnly
              onClick={handleCopyAddress(index)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SettingsPage;
