import React from 'react';
import './Activities.css';
import * as ReactIcons from "react-icons/fa";
import activities from '../../init/activities.json'; 

class Activities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logs: [],
      searchQuery: '',
    };
  }

  componentDidMount() {
    // Fetch activity log data from an API or set it manually
    const logs = activities;

    this.setState({ logs });
  }

  handleSearchChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  render() {
    const { logs, searchQuery } = this.state;

    // Filter logs based on the search query
    const filteredLogs = logs.filter((log) => {
      const { timestamp, action, apiKey } = log;
      const query = searchQuery.toLowerCase();

      return (
        timestamp.toLowerCase().includes(query) ||
        action.toLowerCase().includes(query) ||
        apiKey.toLowerCase().includes(query)
      );
    });

    return (
      <div className="activity-log-component">
        <h3><ReactIcons.FaWaveSquare /> Activity Logs</h3>
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={this.handleSearchChange}
        />
        <table>
          <thead>
            <tr>
              <th>Timestamp</th>
              <th>Action</th>
              <th>API Key</th>
            </tr>
          </thead>
          <tbody>
            {filteredLogs.map((log) => (
              <tr key={log.id}>
                <td>{log.timestamp}</td>
                <td>{log.action}</td>
                <td>{log.apiKey}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default Activities;
