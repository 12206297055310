import React, { useState } from 'react';
import { IconContext } from 'react-icons';
import * as ChainIcons from "@thirdweb-dev/chain-icons";
import './Transactions.css';
import auth from '../../init/auth.json';

import networks from '../../init/networks.json';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


const transactions = [
  { id: 1, date: '2023-06-20', description: 'Payment Received', amount: 500.0 },
  { id: 2, date: '2023-06-19', description: 'Online Purchase', amount: -75.0 },
  { id: 3, date: '2023-06-18', description: 'ATM Withdrawal', amount: -200.0 },
  { id: 4, date: '2023-06-17', description: 'Payment Sent', amount: -150.0 },
  { id: 5, date: '2023-06-16', description: 'Deposit', amount: 1000.0 }
];

const Transactions = () => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [searchFilter, setSearchFilter] = useState('');

  const handleDateChange = dates => {
    setDateRange(dates);
  };

  const handleSearchFilterChange = event => {
    setSearchFilter(event.target.value);
  };

  const filteredTransactions = transactions.filter(transaction => {
    const transactionDate = new Date(transaction.date);
    const startDate = dateRange[0];
    const endDate = dateRange[1];

    return (
      (startDate === null || transactionDate >= startDate) &&
      (endDate === null || transactionDate <= endDate) &&
      transaction.description.toLowerCase().includes(searchFilter.toLowerCase())
    );
  });

  return (
    <div className="transaction-history">
      <h3 >Transaction History</h3>
      <div className="transaction-history__filters">
        <DatePicker
          selected={dateRange[0]}
          onChange={handleDateChange}
          selectsRange
          startDate={dateRange[0]}
          endDate={dateRange[1]}
          placeholderText="Select date range"
          className="transaction-history__date-filter"
        />
        <input
          type="text"
          placeholder="Search by description"
          value={searchFilter}
          onChange={handleSearchFilterChange}
        />
      </div>
      <table className="transaction-history__table">
        <thead>
          <tr>
            <th className="sides">Date</th>
            <th>Description</th>
            <th className="sides">Amount</th>
          </tr>
        </thead>
        <tbody>
          {filteredTransactions.map(transaction => (
            <tr key={transaction.id}>
              <td>{transaction.date}</td>
              <td>{transaction.description}</td>
              <td>{transaction.amount}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Transactions;
