import React, { useState } from 'react';

const BankTransferForm = ({talknojutsu}) => {
  const [recipientName, setRecipientName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [amount, setAmount] = useState('');

  const handleRecipientNameChange = (event) => {
    setRecipientName(event.target.value);
  };

  const handleAccountNumberChange = (event) => {
    setAccountNumber(event.target.value);
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Perform the bank transfer logic
    console.log('Bank transfer submitted:', recipientName, accountNumber, amount);
    talknojutsu(["Payment Transferred"]);
    
  };

  return (
    <div className="bank-transfer-form">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="recipientName">Recipient Name</label>
          <input
            type="text"
            id="recipientName"
            name="recipientName"
            value={recipientName}
            onChange={handleRecipientNameChange}
            placeholder="Enter recipient name"
          />
        </div>
        <div className="form-group">
          <label htmlFor="accountNumber">Account Number</label>
          <input
            type="text"
            id="accountNumber"
            name="accountNumber"
            value={accountNumber}
            onChange={handleAccountNumberChange}
            placeholder="Enter account number"
          />
        </div>
        <div className="form-group">
          <label htmlFor="amount">Amount</label>
          <input
            type="number"
            id="amount"
            name="amount"
            value={amount}
            onChange={handleAmountChange}
            placeholder="Enter amount"
          />
        </div>
        <button type="submit">Transfer</button>
      </form>
    </div>
  );
};

export default BankTransferForm;
