import React, { useState } from 'react';
import './Auth.css';
import { IconContext } from 'react-icons';
import SVG from 'react-inlinesvg';
import * as Icons from 'react-icons/fa';
import * as ChainIcons from "@thirdweb-dev/chain-icons";

const Auth = ({  }) => {
  const earn = [
    {
      "id": 1,
      "productName": "$FTM Staking",
      "description": "Participate in staking and earn rewards by locking up your tokens with our trusted validator.",
      "stakingProvider": "XYZ Staking Platform",
      "minimumStake": 100,
      "interestRate": 8.5,
      "icon":"Fantom"
    },
    {
      "id": 2,
      "productName": "$ATOM Liquidity Provision",
      "description": "Provide liquidity across decentralized exchanges (DEXs) through BBS contracts and earn fees.",
      "exchange": "DEF Exchange",
      "liquidityPool": "ABC-DEF LP",
      "interestRate": 18,
      "icon":"Cosmos"
    },
    {
      "id": 3,
      "productName": "SGD Fixed Deposit",
      "description": "Invest idle cash 1 year and earn fixed interest on your deposit.",
      "bank": "PQR Bank",
      "minimumDeposit": 20000,
      "interestRate": 3.0,
      "icon":"FaUnlockAlt"
    },
    {
      "id": 4,
      "productName": "US T-bills",
      "description": "Invest in low-risks T-bills for 6 months.",
      "bank": "PQR Bank",
      "minimumDeposit": 50000,
      "interestRate": 4.0,
      "icon":"FaUniversity"
    }
    
  ];
  

  const loans = [
    {
      "id": 10,
      "productName": "Corporate Term Loan",
      "description": "Get financing for corporate needs with flexible repayment options.",
      "lender": "GHI Bank",
      "maximumLoanAmount": 500000,
      "interestRate": 7.25,
      "loanType": "Corporate",
      "esgCategory": "",
      "icon":"FaProjectDiagram"
    },
    {
      "id": 11,
      "productName": "ESG Loan",
      "description": "Finance environmentally and socially responsible projects.",
      "lender": "JKL Bank",
      "maximumLoanAmount": 1000000,
      "interestRate": 6.5,
      "loanType": "Corporate",
      "esgCategory": "Environmental, Social, and Governance",
      "icon":"FaLeaf"
    },
    {
      "id": 12,
      "productName": "Working Capital Loan",
      "description": "Support day-to-day operations and short-term financing needs.",
      "lender": "MNO Bank",
      "maximumLoanAmount": 200000,
      "interestRate": 8.75,
      "loanType": "Corporate",
      "esgCategory": "",
      "icon":"FaCalendarAlt"
    },
    {
      "id": 13,
      "productName": "Crypto Collateralized Loan",
      "description": "Borrow funds by providing cryptocurrency as collateral.",
      "lender": "PQR Bank",
      "maximumLoanAmount": 1000000,
      "interestRate": 5.0,
      "loanType": "Crypto Collateralized",
      "esgCategory": "",
      "icon":"FaBitcoin"
    }
  ];
  
  const IComponent = ({ value }) => {
    const IconComponent = Icons[value] || ChainIcons[value];

    return (
      <div>
        <IconContext.Provider value={{ size: '1.5em', height: "20"}} > 
          {IconComponent && <IconComponent style={{width:"25"}} />}
        </IconContext.Provider>
      </div>
    );
  };

  const [selectedWorker, setSelectedWorker] = useState(
  );

  const handleWorkerClick = (workerId) => {
    setSelectedWorker(workerId === selectedWorker ? null : workerId);
  };

  return (
    <div className="auth-container">
      <h3><Icons.FaPiggyBank/> Short-term investment vehicles</h3>
      <div className='listsi'>
      <div className="service-worker-list si">
        {earn.map((worker) => (
          <div
            key={worker.id}
            className={`service-worker-card ${selectedWorker === worker.id ? 'active' : ''}`}
            onClick={() => handleWorkerClick(worker.id)}
          >
            <h3><IComponent value={worker.icon} />{worker.productName}</h3>
            {selectedWorker === worker.id && (
              <ul className="auth-methods">
               {worker.description}
               <div style={{"position":"relative","margin":"20px auto","color":"green"}}>APY {worker.interestRate} %</div>
               
              </ul>
            )}
          </div>
        ))}
      </div></div>
      <h3><Icons.FaSearchDollar/> Loans and working capital</h3>
      <div className='listloan'>
      <div className="service-worker-list loans">
        {loans.map((worker) => (
          <div
            key={worker.id}
            className={`service-worker-card ${selectedWorker === worker.id ? 'active' : ''}`}
            onClick={() => handleWorkerClick(worker.id)}
          >
               <h3><IComponent value={worker.icon} />{worker.productName}</h3>
            {selectedWorker === worker.id && (
              <ul className="auth-methods">
               {worker.description}
               <div style={{"position":"relative","margin":"20px auto","color":"red"}}>EIR {worker.interestRate} %</div>
              </ul>
            )}
          </div>
        ))}
      </div></div>
    </div>
  );
};

export default Auth;
