import React, { useState } from 'react';
import Auth from '../../components/dashboard/Auth';
import ApiUpdate from '../../components/dashboard/ApiUpdate';
import data from '../../init/project.json';
import './config.css';

const Config = ({deployedTemplates,functionOptions,setFunctionOptions}) => {


 

  return (
    <div className="config">

<Auth serviceWorkers={data}/> 

    </div>
  );
};

export default Config;
