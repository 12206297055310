import React from 'react';
import * as ReactIcons from 'react-icons/gr';
import './APIM.css';

class APIM extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apiKeys: [],
      newApiKey: '',
      showSecret: false,
      showApiList: true, // New state for showing/hiding API list
    };
  }

  handleGenerateApiKey = () => {
    const generatedApiKey = this.generateRandomApiKey();
    const generatedApiSecret = this.generateRandomApiKey();
    this.setState((prevState) => ({
      apiKeys: [...prevState.apiKeys, { key: generatedApiKey, secret: generatedApiSecret, showSecret: false }],
    }));
  };

  handleToggleSecret = (apiKey) => {
    this.setState((prevState) => ({
      apiKeys: prevState.apiKeys.map((key) =>
        key.key === apiKey.key ? { ...key, showSecret: !key.showSecret } : key
      ),
    }));
  };

  handleDeleteApiKey = (apiKey) => {
    this.setState((prevState) => ({
      apiKeys: prevState.apiKeys.filter((key) => key.key !== apiKey.key),
    }));
  };

  generateRandomApiKey() {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let apiKey = '';

    for (let i = 0; i < 24; i++) {
      apiKey += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return apiKey;
  }

  render() {
    const { apiKeys, showApiList } = this.state;

    return (
      <div className="api-container">
        <div className="generate-api-key">
        
      <div onClick={() => this.setState({ showApiList: !showApiList })}>
          <h4 >
            API Manager  {showApiList ? (<ReactIcons.GrFormViewHide/>) : (<ReactIcons.GrFormView/>)}
          </h4></div>
        </div>
        {showApiList && (
          <div className="api-list-container">
              <div onClick={this.handleGenerateApiKey}><sup><ReactIcons.GrNewWindow/> New API</sup></div>
            <ul className="api-list">
              {apiKeys.map((apiKey) => (
                <li className="api-item" key={apiKey.key}>
                  {!apiKey.showSecret && <span className="api-key">{apiKey.key}</span> }
                  <button className="api-button" onClick={() => this.handleToggleSecret(apiKey)}>
                    {apiKey.showSecret ? (<ReactIcons.GrFormViewHide/>) : (<ReactIcons.GrFormView/>)}
                  </button>
                  {apiKey.showSecret && <span className="api-secret">{apiKey.secret}</span>}
                  <button className="delete" onClick={() => this.handleDeleteApiKey(apiKey)}>
                  <ReactIcons.GrFormTrash/>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

export default APIM;
