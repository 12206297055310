import React, { useState, useEffect } from 'react';
import './ApiUpdate.css';
import { IconContext } from 'react-icons';
import * as ChainIcons from '@thirdweb-dev/chain-icons';
import * as Icons from 'react-icons/fa';
import networks from '../../init/networks.json';
import axios from 'axios';
import Web3 from 'web3';

const ApiUpdate = () => {
  const [activePlatform, setActivePlatform] = useState('github');
  const [dummyAssets, setDummyAssets] = useState([
    { asset: 'SGD', amount: 15000, category: 'cash' },
    { asset: 'USD', amount: 20000, category: 'cash' },
    { asset: 'AAPL', amount: 10, category: 'securities' },
    { asset: 'MSFT', amount: 15, category: 'securities' },
  ]);

  const IComponent = ({ value }) => {
    if(value=="FTM"){ value="Fantom" }
    if(value=="ATOM"){ value="Cosmos" }
    const IconComponent = Icons[value] || ChainIcons[value];

    return (
      <div>
        <IconContext.Provider value={{ size: '1.5em', height: "20"}} > 
          {IconComponent && <IconComponent style={{width:"25"}} />}
        </IconContext.Provider>
      </div>
    );
  };

  const fetchCosmosBalance = async () => {
    try {
      const selectedNetwork = networks.find((network) => network.id === 'cosmos');
      const response = await axios.get(`${selectedNetwork.rpc}/bank/balances/${selectedNetwork.contractAddress}`);
      const balance = response.data.result;
      const selectedAsset = balance.find((bal) => bal.denom === 'uatom');
      setDummyAssets((prev) => {
        const assetExists = prev.some((item) => item.asset === 'ATOM');

        if (assetExists) {
          return prev.map((item) => {
            if (item.asset === 'ATOM') {
              return { ...item, amount: selectedAsset.amount / 1000000 };
            }
            return item;
          });
        } else {
          return [
            ...prev,
            { asset: 'ATOM', amount: selectedAsset.amount / 1000000, category: 'crypto' },
          ];
        }
      });
    } catch (error) {
      console.error('Error retrieving balance:', error);
    }
  };

  const fetchFantomBalance = async () => {
    try {
      const selectedNetwork = networks.find((network) => network.id === 'fantom');
      const web3 = new Web3(selectedNetwork.rpc);
      const balance = await web3.eth.getBalance(selectedNetwork.contractAddress, 'latest');

      const bal = parseInt(balance) / Math.pow(10, 18);

      console.log(balance, bal);

      setDummyAssets((prev) => {
        const assetExists = prev.some((item) => item.asset === 'FTM');

        if (assetExists) {
          return prev.map((item) => {
            if (item.asset === 'FTM') {
              return { ...item, amount: bal };
            }
            return item;
          });
        } else {
          return [
            ...prev,
            { asset: 'FTM', amount: bal, category: 'crypto' },
          ];
        }
      });
    } catch (error) {
      console.error('Error retrieving balance:', error);
    }
  };

  const handlePlatformChange = (platform) => {
    setActivePlatform(platform);
    if (platform === 'crypto') {
      fetchCosmosBalance();
      fetchFantomBalance();
    }
  };

  // Filter assets based on the activePlatform
  const filteredAssets = dummyAssets.filter((asset) => asset.category === activePlatform);

  useEffect(() => {
    fetchCosmosBalance();
    fetchFantomBalance();
  }, []);

  return (
    <div className="api-update-container">
      <h3>Holdings</h3>
      <div className="tab-menu">
        <button
          className={`tab-item ${activePlatform === 'cash' ? 'active' : ''}`}
          onClick={() => handlePlatformChange('cash')}
        >
          <IComponent value="FaDollarSign" /> Cash
        </button>
        <button
          className={`tab-item ${activePlatform === 'securities' ? 'active' : ''}`}
          onClick={() => handlePlatformChange('securities')}
        >
          <IComponent value="FaChartLine" /> Securities
        </button>
        <button
          className={`tab-item ${activePlatform === 'crypto' ? 'active' : ''}`}
          onClick={() => handlePlatformChange('crypto')}
        >
          <IComponent value="FaBitcoin" /> Crypto
        </button>
      </div>

      <table>
        <thead>
          <tr>
            <th>Asset</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {filteredAssets.map((asset, index) => (
            <tr key={index}>
              <td><IComponent value={asset.asset}  /> {asset.asset}</td>
              <td>{asset.amount}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ApiUpdate;
