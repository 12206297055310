import React, { useState } from 'react';
import ProjectList from '../../components/project/ProjectList'
import './Templates.css';

const Templates = ({contacts, setInitialProjects , initialProjects}) => {

  const [name, setName] = useState('');
  const [chain, setChain] = useState('');
  const [address, setAddress] = useState('');
  const [editingContact, setEditingContact] = useState(null);
  const supportedChains = ['Ethereum', 'Algorand', 'Solana', 'Stellar', 'Avalanche', 'Hedera'];


  return (
    <ProjectList projects={initialProjects} setProjects={setInitialProjects} />
  );
};

export default Templates;
