import React, { useState } from 'react';

const AddPayeeForm = ({talknojutsu}) => {
  const [payeeName, setPayeeName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');

  const handlePayeeNameChange = (event) => {
    setPayeeName(event.target.value);
  };

  const handleAccountNumberChange = (event) => {
    setAccountNumber(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Perform the add payee logic
    talknojutsu([payeeName+" added"]);
    
  };

  return (
    <div className="add-payee-form">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="payeeName">Payee Name</label>
          <input
            type="text"
            id="payeeName"
            name="payeeName"
            value={payeeName}
            onChange={handlePayeeNameChange}
            placeholder="Enter payee name"
          />
        </div>
        <div className="form-group">
          
          <label htmlFor="accountNumber">Account Identifier</label>
          <select><option>Bank</option><option>Crypto</option></select>
          <input
            type="text"
            id="accountNumber"
            name="accountNumber"
            value={accountNumber}
            onChange={handleAccountNumberChange}
            placeholder="Enter account number"
          />
        </div>
        <button type="submit">Add Payee</button>
      </form>
    </div>
  );
};

export default AddPayeeForm;
