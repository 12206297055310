import React, { useState } from 'react';
import Select from 'react-select';
import { IconContext } from 'react-icons';
import SVG from 'react-inlinesvg';
import * as FaIcons from 'react-icons/fa';
import * as ChainIcons from "@thirdweb-dev/chain-icons";
import CryptoTransferForm from "./Crypto";
import BankTransferForm  from "./Bank";
import AddPayeeForm  from "./Payee";

import './Transfers.css';
const Transfers = ({talknojutsu}) => {
  const [activeForm, setActiveForm] = useState('bankTransfer'); // Initialize the active form as 'bankTransfer'


  const handleFormToggle = (formName) => {
    setActiveForm(formName); // Set the active form based on the button clicked
  };

  return (
    <div className="transfers-container">
      <div className='btngroup'>
      <button className={activeForm === 'bankTransfer' ? 'active' : ''} onClick={() => handleFormToggle('bankTransfer')}>
        <FaIcons.FaExchangeAlt />
        <sup><br />Bank<br />Transfer</sup>
      </button>
      <button className={activeForm === 'cryptoAsset' ? 'active' : ''} onClick={() => handleFormToggle('cryptoAsset')}>
        <FaIcons.FaBitcoin />
        <sup><br />Crypto <br />Transfer</sup>
      </button>
      <button  className={activeForm === 'addPayee' ? 'active' : ''} onClick={() => handleFormToggle('addPayee')}>
        <FaIcons.FaUserAlt />
        <sup><br />Add <br />Payee</sup>
      </button>
      </div>
      {activeForm === 'bankTransfer' && (
        <div>
        <BankTransferForm talknojutsu={talknojutsu} />
        </div>
      )}
      {activeForm === 'cryptoAsset' && (
        <div>
        <CryptoTransferForm talknojutsu={talknojutsu}/>
        </div>
      )}
      {activeForm === 'addPayee' && (
        <div>
         <AddPayeeForm talknojutsu={talknojutsu} />
        </div>
     
      )}
         <div className='instructions'><br/>
         {activeForm === 'bankTransfer' && ( <h3><FaIcons.FaExchangeAlt /> <br/>
        Perform regular cash transfers to registered bank accounts</h3>
       )}
      {activeForm === 'cryptoAsset' && (
      <h3><FaIcons.FaBitcoin />  <br/>  Perform crypto asset transfers to travel-rule marked recipients</h3>
      )}
      {activeForm === 'addPayee' && (<h3><FaIcons.FaUserAlt /> <br/>
        Add bank account / Crypto wallet address to perform transfers</h3>
      
      )}
          </div>
    </div>
  );
};

export default Transfers;
