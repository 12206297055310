import React, { useState } from 'react';
import Select from 'react-select';
import { IconContext } from 'react-icons';
import SVG from 'react-inlinesvg';
import networks from "../../init/networks.json";

const CryptoTransferForm = ({talknojutsu}) => {
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [amount, setAmount] = useState('');

  const handleNetworkChange = (selectedOption) => {
    setSelectedNetwork(selectedOption);
    setSelectedAsset("FTM"); // Reset selected asset when changing the network
  };

  const handleAssetChange = (selectedOption) => {
    setSelectedAsset(selectedOption);
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Perform the crypto transfer logic
    console.log('Transfer submitted:', selectedNetwork, selectedAsset, amount);
    var processes =[]; 
    processes[0]= "Validating message";
    processes[1]= "Verifying session id on Relayer_Gauth";
    processes[2]= "Signing message";
    processes[3]= "Transfer posted";
  talknojutsu(processes);
  };

  const networkOptions = networks.map((network) => {
    return {
      value: network.id,
      label: network.label,
    };
  });

  const assetOptions = [{label:"FTM",value:"FTM"},{label:"ATOM",value:"ATOM"}];

  return (
    <div className="crypto-transfer-form">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="network">Blockchain Network</label>
          <Select
            id="network"
            name="network"
            options={networkOptions}
            value={selectedNetwork}
            onChange={handleNetworkChange}
            placeholder="Select network"
          />
        </div>
        <div className="form-group">
          <label htmlFor="cryptoAsset">Crypto Asset</label>
          <Select
            id="cryptoAsset"
            name="cryptoAsset"
            options={assetOptions}
            value={selectedAsset}
            onChange={handleAssetChange}
            placeholder="Select crypto asset"
            isDisabled={!selectedNetwork}
          />
        </div>
        <div className="form-group">
          <label htmlFor="amount">Amount</label>
          <input
            type="number"
            id="amount"
            name="amount"
            value={amount}
            onChange={handleAmountChange}
            placeholder="Enter amount"
          />
        </div>
        <button type="submit" disabled={!selectedAsset || !amount}>
          Transfer
        </button>
      </form>
    </div>
  );
};

export default CryptoTransferForm;
