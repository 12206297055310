import React, { useState } from 'react';
import './RightPanel.css';

import APIM from '../../components/apimanager/APIM';
import Swagger from '../../components/apimanager/Swagger';

const RightPanel = ({ panelIsVisible, setPanelIsVisible, functionOptions ,handleLogout }) => {
  const toggleVisibility = () => {
    setPanelIsVisible(!panelIsVisible);
  };

  const rightPanelStyle = {
    transform: panelIsVisible ? 'translateX(0%)' : 'translateX(100%)',
  };


  return (
    <>
      <div className="right-panel" style={rightPanelStyle}>
     
        <div className='main'>
          <div className="logout" onClick={() => handleLogout()}><sup >Logout</sup></div>
       Onchain LOGS
        </div>
      </div>
      <div className={`rtab ${panelIsVisible ? 'visible' : ''}`} onClick={toggleVisibility}>
     {panelIsVisible ?  '▶': '◀' }
      </div>
    </>
  );
};

export default RightPanel;
